//@ts-nocheck
import { useEffect, useState } from 'react';
import ModalFooter from '../../../commonComponents/ModalFooter';
import ModalHeader from '../../../commonComponents/ModalHeader';
import ModalInput from '../../../commonComponents/ModalInput';
import { ConfigSatIcon } from '../../../commonMMSvgs/MissionModelingSvgs';
import './satelliteConfig.css'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MissionModellingService from '../../../MissionModelling.service';
import { toast } from 'react-toastify';
import { hexToRgbaArray } from '../../../../CommonComponents/CommonFunctions';

const SatelliteConfig = ({ closeModalCreation }) => {
    const dispatch = useDispatch()
    const params = useParams()

    const { mission_id, scenario_id } = params;
    const editConstellationConfig = useSelector(state => state.missionModelling.editConstellationConfig)
    const isModalMinimized = useSelector(state => state.missionModelling.isModalMinimized);

    const orbitColors = [
        "#DB3225", "#E91E63", "#AA25C1", "#703BCD",
        "#4357C4", "#2279BE", "#008EA1", "#009688",
        "#3D9941", "#869118", "#9C8E13", "#C19510",
        "#DF8B0E", "#FF5722"
    ];

    const [selectedOrbitColor, setSelectedOrbitColor] = useState("");

    const [vSwath, setVSwath] = useState('');
    const [hSwath, setHSwath] = useState('');
    const [orientation, setOrientation] = useState('nadir');

    const checkIsValid = () => {
        if (vSwath?.length > 0 && hSwath?.length > 0 && orientation?.length > 0) {
            return true
        }
        return false
    }

    const handleSubmit = () => {
        let isValidSwath = false;
        let maxLimit;

        const combineSwath = parseFloat(vSwath) + parseFloat(hSwath);
        const swathRange = parseFloat(parseFloat(editConstellationConfig.groups[0].referenceOrbit?.semiMajorAxis) - 6371)

        if (swathRange >= 300 && swathRange < 350) {
            if (combineSwath <= 312.34 && combineSwath >= 5) {
                isValidSwath = true;
                maxLimit = 312.34;
            }
        } else if (swathRange >= 350 && swathRange < 400) {
            if (combineSwath <= 364.40 && combineSwath >= 5) {
                isValidSwath = true;
                maxLimit = 364.40;
            }
        } else if (swathRange >= 400 && swathRange < 450) {
            if (combineSwath <= 416.45 && combineSwath >= 5) {
                isValidSwath = true;
                maxLimit = 416.45;
            }
        } else if (swathRange >= 450 && swathRange < 500) {
            if (combineSwath <= 468.51 && combineSwath >= 5) {
                isValidSwath = true;
                maxLimit = 468.51;
            }
        } else if (swathRange >= 500 && swathRange < 550) {
            if (combineSwath <= 520.57 && combineSwath >= 5) {
                isValidSwath = true;
                maxLimit = 520.57;
            }
        } else if (swathRange >= 550 && swathRange < 600) {
            if (combineSwath <= 572.6 && combineSwath >= 5) {
                isValidSwath = true;
                maxLimit = 572.6;
            }
        } else if (swathRange >= 600 && swathRange < 650) {
            if (combineSwath <= 624.68 && combineSwath >= 5) {
                isValidSwath = true;
                maxLimit = 624.68;
            }
        } else if (swathRange >= 650 && swathRange < 700) {
            if (combineSwath <= 676.74 && combineSwath >= 5) {
                isValidSwath = true;
                maxLimit = 676.74;
            }
        } else if (swathRange >= 700 && swathRange < 750) {
            if (combineSwath <= 728.79 && combineSwath >= 5) {
                isValidSwath = true;
                maxLimit = 728.79;
            }
        } else if (swathRange >= 750 && swathRange < 800) {
            if (combineSwath <= 780.85 && combineSwath >= 5) {
                isValidSwath = true;
                maxLimit = 780.85;
            }
        }

        let satelliteSwath = editConstellationConfig?.groups?.[0]?.satellites?.[0]?.config

        let body = {
            config: {
                swath: {
                    type: 'rectangular',
                    value: {
                        horizontal: hSwath,
                        vertical: vSwath,
                    }
                },
                orientation: orientation,
                orbitColour: selectedOrbitColor,
            }
        };

        if (isValidSwath) {
            dispatch(MissionModellingService.editConstellationConfig(editConstellationConfig?.constellationId, mission_id, scenario_id, body, closeModalCreation))
        } else {
            if (maxLimit) {
                toast.error('Swath Limit shoud be between 5 - ', maxLimit)
            } else {
                toast.error('Swath Exceeds the Max Limit')
            }
        }

    }

    useEffect(() => {
        if (Object.keys(editConstellationConfig).length > 0) {
            setVSwath(editConstellationConfig?.groups[0]?.satellites[0]?.config?.swath?.value?.vertical.toString());
            setHSwath(editConstellationConfig?.groups[0]?.satellites[0]?.config?.swath?.value?.horizontal.toString());
            editConstellationConfig?.groups[0]?.satellites[0]?.orbitColour ?
                setSelectedOrbitColor(editConstellationConfig?.groups[0]?.satellites[0]?.orbitColour) :
                setSelectedOrbitColor("#DB3225"); // default red
        }
    }, [editConstellationConfig])

    return (
        <>
            <ModalHeader
                icon={<ConfigSatIcon />}
                title={'CONFIG'}
                handleClose={() => { closeModalCreation() }}
            />

            {!isModalMinimized && (<>
                <div className='satellite_config_section'>
                    <div className='satellite_config_item'>
                        <span>Horizontal Swath</span>
                        <ModalInput
                            unit='km'
                            value={hSwath}
                            handleChange={(value) => {
                                setHSwath(value)
                            }}
                        />
                    </div>
                    <div className='satellite_config_item'>
                        <span>Vertical Swath</span>
                        <ModalInput
                            unit='km'
                            value={vSwath}
                            handleChange={(value) => {
                                setVSwath(value)
                            }}
                        />
                    </div>
                </div>
                {/* <div className='satellite_config_item'>
                <span>Orientation</span>
                <ModalInput
                    value={orientation}
                    handleChange={() => { }}
                />
                </div> */}

                <div className='orbit-color-config'>
                    {orbitColors.map((color) => (
                        <div
                            key={color}
                            onClick={() => setSelectedOrbitColor(color)}
                            className='color-box'
                            style={{
                                backgroundColor: color,
                                border: `2px solid ${selectedOrbitColor === color ? `#CCF54E` : "transparent"
                                    }`,
                                boxShadow: selectedOrbitColor === color ? "0 0 0 2px black" : "none",
                            }}
                        >
                        </div>
                    ))}
                </div>

                <ModalFooter
                    isValid={checkIsValid()}
                    closingText={'Close'}
                    actionText={'Save'}
                    loadingText={'Saving ...'}
                    handleSubmit={() => { handleSubmit() }}
                    handleClose={() => { closeModalCreation() }}
                />
            </>)}
        </>
    );
};

export default SatelliteConfig;
