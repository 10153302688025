import { useEffect, useState } from 'react'
import UserService from '../../../service/UserService'
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { updateUserToken } from '../../CommonComponents/CommonLoginFun';

const User = () => {

    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [tenantList, settenantList] = useState([]);

    const user_tenant_list = () => {
        UserService.getTenantsByUser()?.then(data => {
            if (data?.data) {
                if (data.data.length > 1) {
                    settenantList(data?.data)
                } else {
                    navigate(`${process.env.PUBLIC_URL}/`)
                }
            }
            setLoading(false);
        }).catch(e => {
            console.error(e);
            setLoading(false);
        })
    }
    useEffect(() => {
        user_tenant_list()
    }, [])

    const updateUserTokenData = (tenantId, owner_name) => {
        if (sessionStorage.getItem("userDetails")) {
            setLoading(true)
            // @ts-ignore
            let userData = JSON.parse(sessionStorage.getItem("userDetails"));
            // @ts-ignore
            let token = sessionStorage.getItem("authentication");
            let data = {
                ownerOrg: tenantId,
                firstName: userData?.["given_name"],
                lastName: userData?.["family_name"],
            }
            UserService.updateUser(userData?.["sub"], data)
                ?.then((res) => {
                    if (res) {
                        updateUserToken(token, owner_name, dispatch, navigate, setLoading);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false)
                });
        }
    };

    return (
        <div>
            {loading &&
                <div className="loader-page">
                    <CircularProgress color='inherit' />
                </div>
            }
            <div className="d-flex missions-view p-7" >
                <div className="mission-card-view gap-3">
                    {tenantList?.length > 0 &&
                        tenantList?.map((item, id) => {
                            return (
                                <div
                                    className="mission-card gap-3 d-flex flex-column "
                                    key={id}
                                    onClick={() => {
                                        updateUserTokenData(
                                            item?.["uniqueId"],
                                            item?.['ownerOrg']
                                        );
                                    }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex flex-column">
                                            <span className="mission-name">
                                                {item?.["ownerOrg"]}
                                            </span>
                                            <div className="d-flex align-items-center">
                                                <span
                                                    className="me-2 d-flex gap-1"
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    <span className="mission-alpha-text">
                                                        Created{" "}
                                                    </span>
                                                    :{/* @ts-ignore */}
                                                    <span>{item?.["CreatedAt"]?.slice(0, 10)}</span>
                                                </span>
                                                <span
                                                    className="me-2"
                                                    style={{ marginTop: "-4px" }}
                                                >
                                                    |
                                                </span>
                                                <span
                                                    className="me-2 d-flex gap-1"
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    <span className="mission-alpha-text">
                                                        Last Updated{" "}
                                                    </span>
                                                    :{/* @ts-ignore */}
                                                    <span>{item?.["UpdatedAt"]?.slice(0, 10)}</span>
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="d-flex gap-3 w-100 overflow-hidden justify-content-between">
                                        <div className="mission-alpha w-40">
                                            <strong className="text-truncate">
                                                {item?.["adminFirstName"]} {item?.["adminLastName"]}
                                            </strong>
                                            <span>Admin</span>
                                        </div>
                                        <div className="mission-alpha w-60">
                                            <strong className="text-truncate">
                                                {item?.["adminEmail"]}
                                            </strong>
                                            <span className={item?.["adminEmail"] ? "" : "mt-5"}>
                                                Email
                                            </span>
                                        </div>
                                    </div>

                                    <div className="mission-card-footer w-100 pt-4">
                                        <div className="d-flex gap-2 align-items-center">
                                            {item?.["role"] === "configurator" ? (
                                                <img
                                                    src={
                                                        require("../../../assets/images/standard.svg")
                                                            .default
                                                    }
                                                    height="20px"
                                                    width="20px"
                                                    alt="professional"
                                                />
                                            ) : (
                                                <img
                                                    src={
                                                        require("../../../assets/images/professional.svg")
                                                            .default
                                                    }
                                                    height="20px"
                                                    width="20px"
                                                    alt="professional"
                                                />
                                            )}
                                            <span>{item?.["type"]}</span>
                                        </div>
                                        <div
                                            className="d-flex gap-1 align-items-center">
                                            <strong
                                                style={{ fontSize: "13px", color: "#C6FE02" }}
                                            >
                                                View as member
                                            </strong>
                                            <i
                                                style={{ color: "#C6FE02" }}
                                                className="fe fe-chevron-right fs-16"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    )
}

export default User