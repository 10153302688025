//@ts-nocheck
import { useEffect, useState } from 'react';
import { ActiveConstellation, ActiveSatellite, CloseArrow, CloseIcon, GreenSatIcon, HeaderSatIcon, NormalConstellation, NormalGeoFeature, NormalSat, NormalSatellite, OpenArrow } from '../../../commonMMSvgs/MissionModelingSvgs';
import './satelliteCreationModal.css'
import OrbitalDetails from './OrbitalDetails';
import ModalFooter from '../../../commonComponents/ModalFooter';
import ConstellationConfiguration from './ConstellationConfiguration';
import ModalHeader from '../../../commonComponents/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import MissionModellingService from '../../../MissionModelling.service';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const SatelliteCreationModal = ({ all_satellites, closeModalCreation }) => {

    const dispatch = useDispatch()
    const params = useParams()
    const {
        mission_id,
        scenario_id
    } = params;

    const editConstellationData = useSelector(state => state.missionModelling.editConstellationObject);
    const isModalMinimized = useSelector(state => state.missionModelling.isModalMinimized);
    const creatingConstellation = useSelector(state => state.missionModelling.creatingConstellation);

    const [selectedComponent, setSelectedComponent] = useState('Satellite')
    const [satConfOption, setSatConfOption] = useState<any>()
    const [openSideBarModal, setOpenSideBarModal] = useState('')
    const [orbitType, setOrbitType] = useState('Custom')
    const [isRepeating, setIsRepeating] = useState(false)
    const [satConstellationData, setSatConstellationData] = useState<any>()

    const [openedSubSatelliteModal, setOpenSubSatelliteModal] = useState({
        orbit: true,
        configuration: false
    })
    const [isUpdateEnabled, setUpdateEnabled] = useState(false)
    const get_utc_date_time = (date) => {
        return new Date(Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
            date.getUTCMilliseconds()
        ));
    };

    const [orbitalDetails, setOrbitalDetails] = useState({
        altitude: 450.0,
        semiMajorAxis: 6871.0,
        eccentricity: 0.0,
        inclination: 98.0,
        RAAN: 0.0,
        AOP: 0.0,
        TA: 0.0,
        epoch: get_utc_date_time(new Date())
    })

    const subModalOpenposition = () => {
        setOpenSubSatelliteModal({
            orbit: !openedSubSatelliteModal.orbit,
            configuration: !openedSubSatelliteModal.configuration
        })
    }
    const get_constellation_satellites = (data: any[]) => {
        const all_satellites: any[] = []
        data?.['groups'] && data?.['groups'].map(group => {
            if (group?.['satellites']) {
                all_satellites.push(...group?.['satellites'])
            }
        })
        return {
            satellites: all_satellites,
            counts: all_satellites.length
        }
    }

    const validate_orbit_details = (orbitalDetails) => {
        let validation = false
        if (orbitalDetails.epoch === '' || orbitalDetails.epoch < 0) {
            validation = false
            return toast.error(`Epoch is required`, { toastId: 'error' })
        } else if (orbitalDetails.semiMajorAxis === '' || orbitalDetails.semiMajorAxis > 7121 || orbitalDetails.semiMajorAxis < 6671) {
            validation = false
            return toast.error(`Semi Major Axis should be between 6671 and 7121 km.`, { toastId: 'error' })
        } else if (orbitalDetails.eccentricity === '' || orbitalDetails.eccentricity > 1 || orbitalDetails.eccentricity < 0) {
            validation = false
            return toast.error(`Eccentricity should be between 0 and 1.`, { toastId: 'error' })
        } else if (orbitalDetails.inclination === '' || orbitalDetails.inclination > 180 || orbitalDetails.inclination < 0) {
            validation = false
            return toast.error(`Inclination (i) should be between 0 and 180.`, { toastId: 'error' })
        } else if (orbitalDetails.RAAN === '' || orbitalDetails.RAAN > 360 || orbitalDetails.RAAN < 0) {
            validation = false
            return toast.error(`RAAN (Ω) should be between 0 and 360.`, { toastId: 'error' })
        } else if (orbitalDetails.AOP === '' || orbitalDetails.AOP > 360 && orbitalDetails.AOP < 0) {
            validation = false
            return toast.error(`Argument of periapsis (ω) should be between 0 and 360.`, { toastId: 'error' })
        } else if (orbitalDetails.TA === '' || orbitalDetails.TA > 360 || orbitalDetails.TA < 0) {
            validation = false
            return toast.error(`True Anomaly (ν) should be between 0 and 360.`, { toastId: 'error' })
        } else {
            validation = true
        }
        return validation
    }
    const handleSubmit = () => {
        let body;
        const validate = validate_orbit_details(orbitalDetails)
        if (validate === true) {
            const constellation_satellites = get_constellation_satellites(Object.keys(editConstellationData).length ? editConstellationData : [])

            if (selectedComponent !== 'Satellite' && satConstellationData?.constellationData?.noOfSatellites && (satConstellationData.constellationData.noOfSatellites > (125 - (all_satellites.counts - constellation_satellites.counts)))) {
                return toast.error(`You have already created ${all_satellites.counts} satellites. You can create ${125 - (all_satellites.counts - constellation_satellites.counts)} more satellites.`, { toastId: 'error' })
            }
            else if (selectedComponent !== 'Satellite' && satConstellationData?.constellationData?.noOfPlanes && ((satConstellationData.constellationData.noOfPlanes * satConstellationData.constellationData.noOfSatellitesPerPlane) > (125 - (all_satellites.counts - constellation_satellites.counts)))) {
                return toast.error(`You have already created ${all_satellites.counts} satellites. You can create ${125 - (all_satellites.counts - constellation_satellites.counts)} more satellites.`, { toastId: 'error' })
            } else if (selectedComponent === 'Satellite') {
                body = {
                    "type": "Single",
                    "orbitType": orbitType,
                    "referenceOrbit": {
                        "epoch": typeof orbitalDetails.epoch === 'number' ? orbitalDetails.epoch / 1000 : orbitalDetails.epoch.getTime() / 1000,
                        "semiMajorAxis": orbitalDetails.semiMajorAxis,
                        "eccentricity": orbitalDetails.eccentricity,
                        "inclination": orbitalDetails.inclination,
                        "RAAN": orbitalDetails.RAAN,
                        "AOP": orbitalDetails.AOP,
                        "TA": orbitalDetails.TA
                    }
                }
            } else {
                body = {
                    "type": "Constellation",
                    "orbitType": orbitType,
                    "constellationProperties": {
                        type: satConstellationData?.constellationType,
                        ...satConstellationData?.constellationData
                    },
                    "referenceOrbit": {
                        "epoch": typeof orbitalDetails.epoch === 'number' ? orbitalDetails.epoch / 1000 : orbitalDetails.epoch.getTime() / 1000,
                        "semiMajorAxis": orbitalDetails.semiMajorAxis,
                        "eccentricity": orbitalDetails.eccentricity,
                        "inclination": orbitalDetails.inclination,
                        "RAAN": orbitalDetails.RAAN,
                        "AOP": orbitalDetails.AOP,
                        "TA": orbitalDetails.TA
                    }
                }
            }
            if (Object.keys(editConstellationData).length === 0) {
                dispatch(MissionModellingService.createConstellations(mission_id, body, closeModalCreation, scenario_id))
            } else {
                dispatch(MissionModellingService.editConstellation(editConstellationData?.constellationId, mission_id, body, closeModalCreation, scenario_id))
            }
        }

    }

    useEffect(() => {
        if (Object.keys(editConstellationData).length > 0) {
            setSelectedComponent(editConstellationData.type === "Constellation" ? "Constellation" : "Satellite")
            setOrbitalDetails({
                epoch: editConstellationData?.groups[0]?.referenceOrbit?.epoch * 1000,
                semiMajorAxis: editConstellationData?.groups[0]?.referenceOrbit?.semiMajorAxis,
                eccentricity: editConstellationData?.groups[0]?.referenceOrbit?.eccentricity,
                inclination: editConstellationData?.groups[0]?.referenceOrbit?.inclination,
                RAAN: editConstellationData?.groups[0]?.referenceOrbit?.RAAN,
                AOP: editConstellationData?.groups[0]?.referenceOrbit?.AOP,
                TA: editConstellationData?.groups[0]?.referenceOrbit?.TA
            })
            subModalOpenposition()
        }
    }, [editConstellationData])

    return (
        <>
            <ModalHeader
                icon={<HeaderSatIcon />}
                title={"Satellite"}
                handleClose={() => { closeModalCreation() }}
            />
            {!isModalMinimized && (
                <div className="w-100" style={{ pointerEvents: creatingConstellation ? 'none' : 'auto', opacity: creatingConstellation ? '0.5' : '1' }}>

                    <div className='selectionComponentBox'>
                        <div className='component'>
                            <div className={`componentSvgContainer ${selectedComponent === 'Satellite' ? "active_component" : ''}`} onClick={() => {
                                setSelectedComponent('Satellite')
                            }}>
                                <NormalSat />
                            </div>
                            <span className={`componentTextStyle ${selectedComponent === 'Satellite' ? '' : 'deactive_text'}`}>
                                Satellite
                            </span>
                        </div>
                        <div className='component' >
                            <div className={`componentSvgContainer ${selectedComponent === 'Constellation' ? "active_component" : ''}`} onClick={() => {
                                setSelectedComponent('Constellation')
                            }}>
                                <ActiveConstellation />
                            </div>
                            <span className={`componentTextStyle ${selectedComponent === 'Constellation' ? '' : 'deactive_text'}`}>
                                Constellation
                            </span>
                        </div>
                    </div>

                    {selectedComponent !== 'Satellite' &&
                        <div className='modal_saperator'>
                            <div className='svgContainerHeader' onClick={() => { subModalOpenposition() }}>
                                {openedSubSatelliteModal.orbit ? <OpenArrow /> : <CloseArrow />}
                            </div>
                            <div className='saperator_header'>
                                Reference Orbit
                            </div>
                        </div>}

                    {(openedSubSatelliteModal.orbit || selectedComponent === 'Satellite') &&
                        <OrbitalDetails
                            orbitalDetails={orbitalDetails}
                            setOrbitalDetails={setOrbitalDetails}
                            setOrbitType={setOrbitType}
                            orbitType={orbitType}
                            isRepeating={isRepeating}
                            setIsRepeating={setIsRepeating}
                            isUpdateEnabled={isUpdateEnabled}
                            setUpdateEnabled={setUpdateEnabled}
                        />}

                    {selectedComponent !== 'Satellite' &&
                        <div className='modal_saperator'>
                            <div className='svgContainerHeader' onClick={() => { subModalOpenposition() }}>
                                {openedSubSatelliteModal.configuration ? <OpenArrow /> : <CloseArrow />}
                            </div>
                            <div className='saperator_header'>
                                Constellation Configuration
                            </div>
                        </div>}

                    {(openedSubSatelliteModal.configuration && selectedComponent !== 'Satellite') &&
                        <ConstellationConfiguration
                            orbitalDetails={orbitalDetails}
                            satConstellationData={satConstellationData}
                            setSatConstellationData={setSatConstellationData} />}

                    <ModalFooter
                        isValid={true}
                        actionText={Object.keys(editConstellationData).length === 0 ? 'Create ' : 'Update '}
                        loadingText={Object.keys(editConstellationData).length === 0 ? 'Creating...' : 'Updating...'}
                        closingText={'Close'}
                        handleSubmit={handleSubmit}
                        handleClose={() => { closeModalCreation() }}
                        isUpdateEnabled={isUpdateEnabled}
                    />
                </div>
            )}
        </>
    );
};

export default SatelliteCreationModal;
