import { useEffect } from "react";
import { panelPublicUrl } from "../../../../../../panelurl";
import { DataFlowSvg } from "../scenario_visualization/right_header_section/svgs/Svgs";
import ModalWrapper from "./ModalWrapper";
import { useSelector } from "react-redux";
import { MissionStore } from "../../../../../../store/MissionSlice";

interface ModalProviderProps {
    modalRef: React.MutableRefObject<boolean>
    pinned: boolean;
    open: boolean;
    zIndex: number;
    current_modal: boolean | string;
    expanded: boolean | string;
    set_expanded: (modal: string) => void;
    close_modal: (modal: string) => void;
    pinned_modal: (modal: string) => void;
    set_top_modal: (modal: string) => void;
    dataFlowGraphUrl: any[]
}

const DataFlowGraphModal: React.FC<ModalProviderProps> = ({ open, close_modal, pinned, pinned_modal,
    current_modal, set_top_modal, expanded, set_expanded, dataFlowGraphUrl, modalRef, zIndex }) => {
    const userData = useSelector(
        (state: { mission: MissionStore }) => state.mission.userData
    );

    useEffect(() => {
        window.addEventListener("blur", function (e) {
            setTimeout(function () {
                window.focus();
            }, 0);
        });
    }, [])

    const filteredPanels = dataFlowGraphUrl?.filter(item =>
        userData?.['feature-allowed']?.['dataCompress'] === "Yes"
            ? item?.['panel_name'] !== 'Summary' // Exclude "Summary" when condition is met
            : item?.['panel_name'] !== 'Data Summary' // Exclude "Data Summary" when condition fails
    );

    let reorderedPanels = filteredPanels;

    if (userData?.['feature-allowed']?.['dataCompress'] === "Yes") {
        //Data summary panel should be at top
        const dataSummaryPanel = filteredPanels.find(item => item?.['panel_name'] === 'Data Summary');
        reorderedPanels = [
            ...(dataSummaryPanel ? [dataSummaryPanel] : []),
            ...filteredPanels.filter(item => item?.['panel_name'] !== 'Data Summary'),
        ];
    }

    return (
        <ModalWrapper
            pinned={pinned}
            open={open}
            zIndex={zIndex}
            close_modal={close_modal}
            icon={<DataFlowSvg color="#CCF54E" />}
            label='Data Flow'
            pinned_modal={pinned_modal}
            modal='data_flow'
            expanded={expanded}
            set_expanded={set_expanded}
            current_modal={current_modal}
            set_top_modal={set_top_modal}
            modalRef={modalRef}
        >
            <div className="scenario__modal__container__body overflow-auto" onMouseDown={(e) => e.stopPropagation()}>
                {reorderedPanels?.map((item, index) => (
                    <div className='graph_container' key={index}>
                        <iframe
                            src={`${panelPublicUrl}${item?.['url']}`}
                            margin-height='0'
                            loading="lazy"
                        />
                    </div>
                ))}
            </div>
        </ModalWrapper>
    )
}

export default DataFlowGraphModal