import { useSelector } from "react-redux";
import "./modalFooter.css"
import { CircularProgress } from "@mui/material";

interface ModalFooterProps { isValid: boolean, actionText: string, loadingText: string, closingText: string, handleSubmit: () => void, handleClose: () => void, isUpdateEnabled: boolean }

const ModalFooter = ({ isValid, actionText, loadingText, closingText, handleSubmit, handleClose, isUpdateEnabled }: ModalFooterProps) => {

    const creatingConstellation = useSelector((state: { missionModelling: { creatingConstellation: boolean } }) => state.missionModelling.creatingConstellation)
    const actionClass =
        (actionText === "Update " || isUpdateEnabled || actionText === "Add") ? `modalFooterapply-update ${isUpdateEnabled ? "enable-btn" : ""}`
            : actionText === "Create " ? "modalFooterapply-create" : "";

    return (
        <div className="modalFooter-edit">
            <button
                disabled={creatingConstellation}
                className="modalFooterclose-edit"
                onClick={() => { handleClose() }}
            >
                {closingText}
            </button>
            <button
                disabled={creatingConstellation}
                className={`modalFooterapply-edit ${actionClass} ${isValid ? "enable_analysis_button-edit" : "disable_analysis_button-edit"
                    }`}
                onClick={() => { isValid && handleSubmit(); }}
            >
                {creatingConstellation ? loadingText : actionText}
                {creatingConstellation && <CircularProgress size={10} className="text-white" />}
            </button>
        </div >
    )
};

export default ModalFooter;