//@ts-nocheck
import React, { Fragment, useEffect, useState } from 'react'
import * as Cesium from "cesium";
import { useParams } from 'react-router-dom';
import MissionService from '../../../../service/MissionService';

interface VisualizationProps {
  fetch_orbit_details: any;
  setLoadingScreen: (data) => void;
}

const Visualization: React.FC<VisualizationProps> = ({ fetch_orbit_details, setLoadingScreen }) => {
  const [mode, setMode] = useState("2D")

  const params = useParams();
  const { mission_name } = params

  const setOrbitDetails = (viewer) => {
    const propagation = true
    MissionService.getOribtData(fetch_orbit_details?.['scenario_id'], fetch_orbit_details?.['truetwin_id'], propagation)?.then((res) => {
      if (res?.data) {
        viewer.dataSources.add(Cesium.CzmlDataSource.load(res.data))
        setLoadingScreen(false);
      }
    }).catch((err) => {
      console.log(err)
      setLoadingScreen(false);
    })
  }

  const resetPosition = (viewer) => {
    viewer.camera.setView({
      destination: Cesium.Cartesian3.fromDegrees(0, 0, 39000000)
    });
  }

  let imaginary = new Cesium.MapboxStyleImageryProvider({
    styleId: 'satellite-v9',
    accessToken: process.env.REACT_APP_MAPBOX_TOKEN
  });

  useEffect(() => {
    const viewer = new Cesium.Viewer("operateCesiumContainer", {
      requestRenderMode: true,
      shouldAnimate: true,
      animation: false, // Disable the default animation widget
      baseLayerPicker: false, // Disable the default base layer picker
      timeline: false, // Disable the timeline
      geocoder: false, // Disable the geocoder
      homeButton: false, // Disable the home button
      navigationHelpButton: false, // Disable the navigation help button
      sceneModePicker: false, // Disable the scene mode picker
      selectionIndicator: false, // Disable the selection indicator
      fullscreenButton: false,
      imageryProvider: imaginary,
    });
    viewer.scene.globe.baseColor = Cesium.Color.WHITE; // Set a base color for the globe
    viewer.scene.globe.enableLighting = false; // Enable lighting to ensure consistent shading
    viewer.scene.globe.showGroundAtmosphere = false; // Show the atmospheric effects
    viewer.scene.gamma = 2.0; // Adjust the value based on your observation

    if (fetch_orbit_details) {
      setOrbitDetails(viewer)
    } else {
      setLoadingScreen(false)
    }

    viewer.clock.currentTime = viewer.clock.startTime;
    viewer.clock.shouldAnimate = true;
    viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;
    viewer.scene.screenSpaceCameraController.enableRotate = true;
    // viewer.scene.screenSpaceCameraController.enableTranslate = true;
    viewer.scene.screenSpaceCameraController.enableZoom = true;
    viewer.scene.screenSpaceCameraController.enableTilt = true;
    viewer.scene.screenSpaceCameraController.enableLook = true;
    viewer.scene.highDynamicRange = false
    viewer.scene.skyAtmosphere.show = false;

    const toggleTranslation = () => {
      const zoomLevel = viewer.camera.getMagnitude();
      if (viewer.scene.mode === Cesium.SceneMode.SCENE2D && zoomLevel >= 40000000 || zoomLevel === 31890685) {

        resetPosition(viewer)
      }
    }

    toggleTranslation();
    viewer.camera.moveEnd.addEventListener(toggleTranslation);

    viewer.scene.morphTo2D(0);

    viewer.camera.flyTo({
      // Use flyTo instead of setView
      destination: Cesium.Cartesian3.fromDegrees(0, 0, 50000000), // Set the initial view position to be full view
      orientation: {
        heading: Cesium.Math.toRadians(10),
        pitch: Cesium.Math.toRadians(-90),
        roll: 0,
      },
    });


    return () => {
      viewer.camera.moveEnd.removeEventListener(toggleTranslation);
      viewer.destroy();
    };
  }, [fetch_orbit_details]);

  return (
    <div className='w-100 h-100 d-flex z-0 align-items-center justify-content-center position-relative'>
      <div className='w-90 h-10 d-flex align-items-center justify-content-start px-9 position-absolute top-40px z-3'>
        <div className='d-flex align-items-center gap-4'>
          <div className='w-auto h-auto d-flex flex-column rounded-2 py-3 px-4 bg-black'>
            <div className='w-100 d-flex gap-3'>
              <div className='d-flex gap-2'>
                <span>Storage: </span>
                <span className='color-atmos'>70% </span>
              </div>
              <div>|</div>
              <div className='d-flex gap-2'>
                <span>Task: </span>
                <span className='color-atmos'>70% </span>
              </div>
              <div>|</div>
              <div className='d-flex gap-2'>
                <span>Power: </span>
                <span className='color-atmos'>70% </span>
              </div>
            </div>
            <div>Satellite performance efficiency </div>
          </div>
          <div className='w-auto h-auto d-flex flex-column rounded-2 py-3 px-4 bg-black'>
            <div className='w-100 d-flex gap-4'>
              <div className='color-atmos'>
                {mission_name}
              </div>
              <div className='d-flex gap-2'>
                <span>Ranchio (RNC), USA </span>
                <div>|</div>
                <span>Maneuver </span>
              </div>
            </div>
            <div className='d-flex w-100 align-items-center justify-content-between'>
              <span>Upcoming Event</span>
              <span>in next 15 mins</span>
            </div>
          </div>
        </div>
      </div>
      <div id="operateCesiumContainer" className='w-100 h-100 flex-1' />
    </div>
  )
}

export default Visualization